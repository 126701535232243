


























































































.kiwi-serverview {
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
}

.kiwi-serverview .kiwi-messagelist {
    padding-top: 0;
    height: 100%;
    margin: 0;
}

.kiwi-serverview-inner {
    /* Mobile safari can't work out the height for scrolling to work without this wrapper element */
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.kiwi-serverview-alerts {
    margin-bottom: 1em;
}

