



















































































































.kiwi-mediaviewer {
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
    height: 0;
}

.kiwi-mediaviewer-controls {
    position: absolute;
    top: 0;
    right: 16px;
    z-index: 1;
    background: var(--brand-default-bg);
    border-radius: 5px;
    padding: 3px;
    opacity: 0;
    transition: opacity 0.1s;
    box-shadow: 0 1px var(--brand-input-border);
}

.kiwi-mediaviewer:hover .kiwi-mediaviewer-controls {
    opacity: 1;
}

.kiwi-mediaviewer-controls-close {
    padding: 3px 15px;
}

.kiwi-mediaviewer-content {
    height: 100%;
    overflow: auto;
}

.kiwi-mediaviewer-iframe {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    border: none;
}

