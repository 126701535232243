










































































































































































































































































.kiwi-sidebar {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    z-index: 100;
}

.kiwi-sidebar.kiwi-sidebar-section-settings {
    width: 500px;
    max-width: 500px;
}

.kiwi-sidebar .u-form textarea {
    min-width: 100%;
    max-width: 100%;
    min-height: 80px;
    resize: vertical;
}

.kiwi-sidebar-buffersettings {
    overflow: hidden;
    height: 100%;
}

.kiwi-sidebar-buffersettings .u-tabbed-content {
    padding: 1em;
}

.kiwi-sidebar-userbox {
    overflow: hidden;
    height: 100%;
}

.kiwi-sidebar-settings {
    margin-bottom: 20px;
}

.kiwi-sidebar-settings label {
    display: block;
}

@keyframes settingstransition {
    from { margin-top: 50px; }
    to { margin-top: 100px; }
}

@keyframes nicklisttransition {
    from { height: 0; }
    to { height: 100%; }
}

.kiwi-sidebar-accesstab {
    margin-right: 1em;
}

.kiwi-sidebar-accesstab--active {
    font-weight: bold;
}

.kiwi-channelbanlist-empty {
    margin-top: 10px;
}

.kiwi-sidebar-options {
    display: none;
}

@media screen and (max-width: 769px) {
    .kiwi-sidebar-options {
        display: block;
        cursor: pointer;
        font-weight: 600;
        width: 100%;
        position: relative;
        box-sizing: border-box;
        text-transform: uppercase;
        line-height: 47px;
        vertical-align: top;
    }

    .kiwi-sidebar-options .kiwi-sidebar-close {
        width: 100%;
        display: block;
        padding: 0 15px;
        height: 50px;
        line-height: 50px;
        text-align: right;
        box-sizing: border-box;
        letter-spacing: 2px;
        transition: background 0.3s;
    }

    .kiwi-sidebar-options .kiwi-sidebar-close i {
        margin-left: 5px;
        font-size: 1.5em;
        line-height: 47px;
        position: relative;
        top: 2px;
    }

    .kiwi-sidebar .u-tabbed-view-tab {
        width: 100%;
    }

    .kiwi-sidebar .u-tabbed-view-tab.u-tabbed-view-tab--active {
        border-bottom: 3px solid #42b992;
        margin-bottom: 0;
    }

    .kiwi-sidebar .u-form input[type="checkbox"] {
        margin-right: 4px;
    }

    .kiwi-sidebar .u-form label span {
        margin-right: 0;
        margin-left: 0;
    }

    .kiwi-container--sidebar-drawn .kiwi-sidebar {
        width: 100%;
        max-width: 100%;
    }

    .kiwi-sidebar-buffersettings {
        padding-bottom: 10px;
    }

    .kiwi-channelbanlist {
        float: left;
        width: 100%;
    }

    .kiwi-channelbanlist-table {
        margin-top: 30px;
    }

    .kiwi-channelbanlist .u-form {
        line-height: 10px;
    }
}

