































































































































.embedly-card {
    display: block;
    margin: 4px 0;
}

.embedly-card-hug {
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    background: #fff;
}

.kiwi-mediaviewer-embedly {
    display: inline-block;
    overflow: auto;
}

.kiwi-main-mediaviewer .kiwi-mediaviewer-embedly {
    display: block;
}

